import React from "react";
import placeHolder from "../../styles/images/placeholder-blog-thumbnail.png";
import { resizeAndConvertImage } from "../../utils/common";

export default ({ data: photo, width = 350, addClass = "" }) => {
  return (
    <img
      src={`${photo ? photo.url : placeHolder}?${resizeAndConvertImage(900)}`}
      alt={photo ? photo.alt : "Event"}
      width={width}
      className={addClass}
    />
  );
};
