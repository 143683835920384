import React from "react";
import { Link } from "gatsby";
import { getSafe } from "../../utils/common";
import moment from "moment";
import EventCover from "./event-cover";
import ReactMarkdown from "react-markdown";

export default ({ data: event }) => {    
  let eventDate = moment(event.eventStartTime);
  let eventDay = eventDate.format("DD");
  let eventMonth = getSafe(() => eventDate.format("MMM").toUpperCase());
  return (
    <section class="white-section upcoming-event">
      <div class="container grid">
        <div class="upcoming-event-container">
          {!event.feature && (
            <div>
              <div class="calendar-icon">
                <div>{eventDay}</div>
                <div class="calendar-mth">{eventMonth}</div>
              </div>
              <div class="small-uppercase revert">
                Upcoming Event:
                <br />
                { event.eventLocation || "Singapore" }
              </div>
            </div>
          )}

          <div class="sm-spacing" />
          <h1 class="h-nofixedwidth">{event.title}</h1>
          <div class="sm-spacing" />
          <ReactMarkdown
            source={event.highlightDescription}
            escapeHtml={false}
          />
          <div class="sm-spacing" />
          <Link to={`/event/${event.slug}`} class="btn w-button">
            {event.buttonText}
          </Link>
        </div>
        <div class="upcoming-event-thumb">
          <EventCover data={event.coverPhoto} width={445} />
        </div>
        <div class="md-spacing" />
        <div class="container grid">
          <div class="upcoming-speakers">
            {event.speakers.map(speaker => (
              <img
                src={speaker.profilePicture.url}
                alt={speaker.name}
                title={speaker.name}
                class="upcoming-speaker-avatar"
              />
            ))}
          </div>
          {!!getSafe(() => event.partners.length) && (
            <div class="event-partner-container">
              <div class="small-copy">Our Partner</div>
              <div class="w-clearfix">
                {event.partners.map(partner => (
                  <div class="event-partner-logo">
                    <img
                      key={partner.id}
                      src={partner.url}
                      alt={partner.alt}
                      style={{ minWidth: "96px" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div class="dark-gradient" />
    </section>
  );
};
