import React from "react";
import EventGallery from "./gallery";

export default ({ data: gallery, homeData }) => {
  return (
    <div className="white-section reducedbottom">
      <div className="container center">
        <h2 className="h2">{homeData.galleryHeading || 'Event Gallery'}</h2>
        <div className="sm-spacing" />
        <p className="p center-align">{homeData.gallerySectionDescription || 'Check out our last events'}</p>
        <div className="md-spacing" />
      </div>
      <div className="container">
          <EventGallery images={gallery} gridNumber="5"></EventGallery>
      </div>
    </div>
  );
};
