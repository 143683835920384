import React from "react";
import Speaker from "./speaker";

export default ({ data: speakers, homeData }) => {
  return (
    <section class="white-section gray">
      <div class="container left-md">
        <h2 class="h2">{homeData.speakerSectionHeading || 'Some of Our Speakers'}</h2>
        <div class="sm-spacing" />
        <p class="p center-align">{homeData.speakerSectionDescription || 'Find out more about our speakers'}</p>
        <div class="md-spacing" />
        <div className="grid-container-5">
          {speakers.map(speaker => (
            <div key={speaker.id}>
              <Speaker data={speaker} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
