import React from "react";
import { formatDateRange, isPastADate } from "../../utils/common";
import moment from "moment";
import { Link } from "gatsby";
import EventCover from "./event-cover";
import PlaceHolder from "../blog/blogPreviewPlaceHolder";

export default ({ data: events, homeData }) => {  
  const fullFormat = "Do MMM YYYY - hh.mm A";
  return (
    <section class="white-section centered">
      <div class="container">
        <h2 class="h2">{homeData.recentEventsHeading || 'Most Recent Events'}</h2>
        <div class="sm-spacing" />
        <p class="p center-align">{homeData.recentEventsSectionDescription || 'Find out more about our speakers'}</p>
        <div class="md-spacing" />
        <div className="grid-container-3">
          {events.map(({ node: event }) => (
            <Link
              to={`/event/${event.slug}`}
              class="shadow-box videos w-inline-block"
            >
              <div class="video-container">
                <EventCover data={event.coverPhoto} addClass="video-thumb" />
              </div>
              <div class="platform-detail videos">
                {!isPastADate(event.eventStartTime) && (
                  <React.Fragment>
                    <div class="small-copy event">
                      {formatDateRange(fullFormat, moment(event.eventStartTime))}
                    </div>
                    <div class="tn-spacing" />
                  </React.Fragment>
                )}

                <h6 class="h6">{event.title}</h6>
              </div>
            </Link>
          ))}
        </div>

        <PlaceHolder />
      </div>
    </section>
  );
};
