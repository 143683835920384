import React from "react";
import { graphql } from 'gatsby'
import RecentEvents from "../components/event/recent-events";
import OurSpeakers from "../components/event/our-speakers";
import UpcomingEvent from "../components/event/upcoming-event";
import EventGallery from "../components/event/slider";
import SpeakerModal from "../components/event/speaker-modal";
import ReactMarkdown from "react-markdown";
import { getSafe, resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import Layout from "../layouts";

function getGallery(data) {
  let gallery = [];
  try {
    gallery = data.galleryPhotos
  } catch (error) {}
  return gallery;
}

export default ({
  data: {
    datoCmsEventHome,
    upcomingEvents,
    recentEvents: { edges: recentEvents },
    featureEvents,
    allDatoCmsNotification: { edges: notifications },
  }
}) => {
  resetWebflow("5b987f8f9f15d1e7f60ebfa2");
  let upcoming = getSafe(() => upcomingEvents.edges[0].node);
  let feature = getSafe(() => featureEvents.edges[0].node);  
  let highlightEvent = upcoming || feature;
  let gallery = getGallery(datoCmsEventHome);
  if (upcoming) {
    try {
      recentEvents = recentEvents.filter(
        ({ node: event }) => event.slug !== upcoming.slug
      ).slice(0, 6);
    } catch (error) {
      recentEvents = recentEvents.slice(0, 6);
    }
  } else {
    recentEvents = recentEvents.slice(0, 6);
  }
  let isReducedBottom = !highlightEvent && "reducedbottom" 

  return (
    <Layout>
      <HelmetDatoCms seo={datoCmsEventHome.seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={datoCmsEventHome.notificationPage}
      />
      <header className={`main-header about ${isReducedBottom}`}>
        <div className="container">
          <h1 className="h-nofixedwidth">{datoCmsEventHome.heading}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">
            <ReactMarkdown
              source={datoCmsEventHome.description}
              escapeHtml={false}
            />
          </div>
        </div>
        <div className="container grid" />
      </header>
      {!!highlightEvent && <UpcomingEvent data={highlightEvent} />}
      <RecentEvents data={recentEvents} homeData={datoCmsEventHome} />
      {datoCmsEventHome.speakerList && <OurSpeakers data={datoCmsEventHome.speakerList} homeData={datoCmsEventHome} />}
      {datoCmsEventHome.speakerList && datoCmsEventHome.speakerList.map(speaker => (
        <SpeakerModal data={speaker} />
      ))}

      {!!gallery && <EventGallery data={gallery} homeData={datoCmsEventHome} />}
    </Layout>
  );
};

export const query = graphql`
  query EventHomeQuery {
    datoCmsEventHome {
      heading
      description
      recentEventsHeading
      recentEventsSectionDescription
      speakerSectionHeading
      speakerSectionDescription
      galleryHeading
      gallerySectionDescription
      speakerList {
        id
        name
        role
        bio
        profilePicture {
          url
        }
      }
      galleryPhotos {                      
        alt
        url
      }
      notificationPage {
        id
        page
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    upcomingEvents: allDatoCmsEventDetail(
      filter: { upcoming: { eq: true } }
      sort: { fields: [meta___updatedAt], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          slug
          title
          hubspotFormId
          formDescription
          buttonText
          addressLocation {
            latitude
            longitude
          }
          eventLocation
          subTitle
          eventStartTime
          eventEndTime
          detail
          highlightDescription
          address
          addToCalendar
          partners {
            url
            alt
          }
          coverPhoto {
            url
          }
          gallery {
            alt
            url
          }
          speakers {
            id
            name
            role
            bio
            profilePicture {
              url
            }
          }
          feature
        }
      }
    }
    featureEvents: allDatoCmsEventDetail(
      filter: { feature: { eq: true } }
      sort: { fields: [meta___updatedAt], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          slug
          title
          hubspotFormId
          formDescription
          buttonText
          addressLocation {
            latitude
            longitude
          }
          eventLocation
          subTitle
          eventStartTime
          eventEndTime
          detail
          highlightDescription
          address
          addToCalendar
          partners {            
            url
            alt
          }
          coverPhoto {
            url
          }
          gallery {            
            alt
            url
          }
          speakers {
            id
            name
            role
            bio
            profilePicture {
              url
            }
          }
          feature
        }
      }
    }
    recentEvents: allDatoCmsEventDetail(sort: {fields: [eventStartTime], order: DESC}, limit: 7, filter: {hideEvent: {eq: false}}) {
      edges {
        node {
          slug
          title
          eventStartTime
          eventEndTime
          coverPhoto {
            url
          }
          addressLocation {
            latitude
            longitude
          }
          eventLocation
          gallery {            
            alt
            url
          }
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
